<template>
  <div id="app">
    <router-view/>
    <!-- <to-watch-list v-if="currentName !=='List' "></to-watch-list> -->
  </div>
</template>
<style lang="scss">
@import url('./assets/css/common.scss');
@import url('./assets/css/reset.scss');
.el-checkbox__inner{
  border: 1px solid #5E5F72 !important;
  background-color:transparent!important;
}
.el-checkbox{
  color: rgba(255,255,255,0.9) !important;
  width:120px;
  margin-right:0 !important;
  text-align: left;
}
.el-checkbox__input.is-checked .el-checkbox__inner{
  border: 1px solid #409EFF !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner::after{
  border: 1px solid #409EFF;
  border-left: none;
  border-top: none;
}

</style>
<script>

export default {
  name: 'app',
  data () {
    return {
    }
  },
  created () {
  },
  methods: {},
}
</script>
