import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from './https'
import store from './store'
import './plugins/element.js'

import qs from 'qs'
import '@/assets/iconfont/iconfont.css'
import uploader from 'vue-simple-uploader'
import filters from './filters/index'
import clearTabIndex from './plugins/clearTabIndex.js' // 删除el-tooltip tabindex，解决tooltip包裹的内容点击后仍然出现的问题（点击跳出页面，再返回原页面tooltip仍然存留）
import infiniteScroll from "vue-infinite-scroll";
clearTabIndex.install(Vue)
Object.keys(filters).forEach(key => Vue.filter(key, filters[key]))

Vue.use(infiniteScroll);
Vue.use(uploader)
Vue.prototype.$http = axios
Vue.prototype.qs = qs
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
