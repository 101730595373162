import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {
      username:'', // 用户名
      department:'', // 部门
      position: '' // 职位
    },
    username:'',
    // 邮箱
    email:localStorage.getItem('email'),
    // 品牌
    company_name:localStorage.getItem('company_name'),

    // 控制加入观看列表的提示的显示隐藏
    addSuccess:false,
    playing:0,//控制小窗观看列表跟素材详情视频只能其中一个播放,1小窗播放，2素材详情播放
    //控制删除观看列表某一项
    Delete:false,
    allrourse: false,
    myCollection: false,
    myMaterial: false,
    tag: false,
    tagGroup: false,
    uploading: false,
    recycle: false,
    imgUploading: false,
    updateStripe: {
      type: "",
      update: false
    }, // 通知拆条列表更新
    materialDetail: false
  },
  mutations: {
    setUserInfo(state, val) {
      state.userInfo = val
    },
    // 修改邮箱
    setEmail(state, val) {
      state.email = localStorage.getItem('email') ? localStorage.getItem('email') : ''
    },
    // 修改品牌
    setBrand(state, val) {
      state.company_name = localStorage.getItem('company_name')
    },
    // 修改加入成功提示的值
    editAdd(state,val){
      state.addSuccess=val
    },
    // 修改加入成功提示的值
    deleteItem(state,val){
      state.Delete=val
    },
    // 控制小窗观看列表跟素材详情视频只能其中一个播放
    setplaying(state,val){
      state.playing=val
    },
    //全部素材
    allroursePage(state,val){
      state.allrourse = val
    },

    //我的收藏
    myCollectionPage(state, val) {
      state.myCollection = val
    },

    //我的素材
    myMaterialPage(state, val) {
      state.myMaterial = val
    },

    //标签
    tagPage(state, val) {
      state.tag = val
    },

    //标签组
    tagGroupPage(state, val) {
      state.tagGroup = val
    },

    //上传列表
    uploadingPage(state, val) {
      state.uploading = val
    },

    //回收站
    recyclePage(state, val) {
      state.recycle = val
    },

    //上传 动图/静图切换
    setImgUploading(state,val) {
      state.imgUploading = val
    },

    // 通知拆条列表更新
    stripeUpdate(state,val){
      state.updateStripe = val
    },

    goMaterialDetail(state,val) {
      state.materialDetail = val
    }

  },
  actions: {
  },

  modules: {
  }
})
